import Image from "next/legacy/image";

import gasImageUrl from 'public/images/do-you-smell-gas.jpg';
import {componentsJson} from "~/constants/components";

export function DoYouSmellGas() {

    const { doYouSmellGasText, doYouSmellGasText2} = componentsJson.BillManagement.DoYouSmellGas

  return (
    <div className="flex h-full flex-col rounded-lg bg-white shadow-card">
      <Image
        src={gasImageUrl.src}
        alt=""
        className="h-full w-full rounded-t-lg"
        width={gasImageUrl.width}
        height={gasImageUrl.height}
      />
      <div className="p-5">
        <p className="text-2xl font-semibold text-black/90">
            {doYouSmellGasText}
        </p>
        <p className="mt-1 text-sm text-black/60">
            {doYouSmellGasText2}
        </p>
      </div>
    </div>
  );
}
