import * as React from 'react';
import Link from 'next/link';

import Chevron from 'public/icons/chevron.svg';

type Props =
  | {
      title: string;
      link: string;
      linkText: string;
    }
  | {
      title: string;
      link?: never;
      linkText?: never;
    };

const CardTitle: React.VFC<Props> = ({ title, link, linkText }) => {
  if (link) {
    return (
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-semibold">{title}</h2>
        <Link legacyBehavior href={link}>
          <a className="flex items-center">
            <span className="mr-2">{linkText}</span>
            <span className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-200">
              <Chevron className="h-4 translate-x-[1px] transform" />
            </span>
          </a>
        </Link>
      </div>
    );
  }

  return <h2 className="mb-4 text-xl font-semibold">{title}</h2>;
};

export { CardTitle };
