import Image from "next/legacy/image";

import { CTA } from '../cta';

import furnaceImageUrl from 'public/images/furnace-boost.png';
import {componentsJson} from "~/constants/components";

export const FurnaceBoost = () => {

    const { furnaceBoostText3, furnaceBoostText2, furnaceBoostText } = componentsJson.BillManagement.FurnaceBoost
    return (
        <div className="flex h-full flex-col rounded-lg bg-white shadow-card">
            <Image
                src={furnaceImageUrl.src}
                alt=""
                className="h-full w-full rounded-t-lg"
                width={furnaceImageUrl.width}
                height={furnaceImageUrl.height}
            />
            <div className="p-5">
                <p className="text-2xl font-semibold text-black/90">
                    {furnaceBoostText}
                </p>
                <p className="mt-1 text-sm text-black/60">
                    {furnaceBoostText2}
                </p>
                <div className="pt-4">
                    <CTA
                        as="externalLink"
                        href={furnaceBoostText3.value}
                        target="_blank"
                    >
                        {furnaceBoostText3.label}
                    </CTA>
                </div>
            </div>
        </div>
    );
}
