import { fetcher } from './fetcher';
import {
  HomeProfileAPIRequest,
  HomeProfileAPIResponse,
} from '~/types/homeprofile';

export async function fetchHomeCharacteristics(
  opts: HomeProfileAPIRequest,
  traceparent: string,
  xraytraceid: string
): Promise<HomeProfileAPIResponse> {
  try {
    const url = new URL(
      `/api/HomeCharacteristics/customersitecharacteristics/${opts.serviceNetworkId}/${opts.customerId}/${opts.customerSiteId}`,
      process.env.CORTEX_API
    );
    return await fetcher<HomeProfileAPIResponse>(url.toString(), {
      method: 'GET',
      headers: {
        traceparent: traceparent,
        'x-amzn-trace-id': xraytraceid,
      },
    });
  } catch (error: unknown) {
    console.error(error);
    return;
  }
}

export async function fetchUpdateHomeCharacteristics(
  opts: HomeProfileAPIRequest,
  homeType: string,
  homeSize: string,
  countPeople: string,
  traceparent: string,
  xraytraceid: string,
  homeProfileVersion?: string | unknown
) {
  try {
    const url = 
      '/api/cortex/HomeCharacteristics/customersitecharacteristics/' +opts.serviceNetworkId +
        '/' +
        opts.customerId +
        '/' +
        opts.customerSiteId;

    const body = JSON.stringify({
      iscomplete: false,
      version: homeProfileVersion,
      homecharacteristics: [
        { name: 'HomeType', value: homeType },
        { name: 'HomeSize', value: homeSize },
        { name: 'CountPeople', value: countPeople },
      ],
    });

    return await fetch(url, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
        traceparent: traceparent,
        'x-amzn-trace-id': xraytraceid,
      },
    });
  } catch (error: unknown) {
    console.error(error);
    return;
  }
}
