import dynamic from 'next/dynamic';
import * as React from 'react';

// import { getRandomItem } from '~/utils/random';

// const Upsell1A = dynamic(async () => {
//   const m = await import('~/components/upsell/1a');
//   return m.Upsell1A;
// });
// const Upsell1B = dynamic(async () => {
//   const m = await import('~/components/upsell/1b');
//   return m.Upsell1B;
// });
const Upsell2A = dynamic(async () => {
  const m = await import('~/components/upsell/2a');
  return m.Upsell2A;
});
// const Upsell2B = dynamic(async () => {
//   const m = await import('~/components/upsell/2b');
//   return m.Upsell2B;
// });

// const randomUpsell = getRandomItem(['1a', '1b', '2a', '2b']);

const UpsellABTest: React.VFC = () => {
  return <Upsell2A />;
  // if (randomUpsell === '1a') {
  //   return <Upsell1A />;
  // }

  // if (randomUpsell === '1b') {
  //   return <Upsell1B />;
  // }

  // if (randomUpsell === '2a') {
  //   return <Upsell2A />;
  // }

  // return <Upsell2B />;
};
export { UpsellABTest };
