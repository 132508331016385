import Image from "next/legacy/image";
import { CTA } from '../cta';
import energyUsageImageUrl from 'public/images/energy-usage-data.png';
import {componentsJson} from "~/constants/components";


export function EnergyUsage() {

  const { energyUsageText2, energyUsageText, energyUsageText3 } = componentsJson.BillManagement.EnergyUsage

  return (
    <div className="flex h-full flex-col rounded-lg bg-white shadow-card">
      <Image
        src={energyUsageImageUrl.src}
        alt="Energy Usage"
        className="h-full w-full rounded-t-lg"
        width={energyUsageImageUrl.width}
        height={energyUsageImageUrl.height}
      />
      <div className="p-5">
        <p className="text-2xl font-semibold text-black/90">
          {energyUsageText}
        </p>
        <p className="mt-1 text-sm text-black/60">
          {energyUsageText2}
        </p>
        <div className="mt-4">
          <CTA
            as="externalLink"
            href={'/energyusagedata'}
            target="_blank"
          >
            {energyUsageText3}
          </CTA>
        </div>
      </div>
    </div>
  );
}
