import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useMedia } from 'react-use';
import clsx from 'clsx';

import Chevron from 'public/icons/chevron.svg';

type Direction = 1 | -1;

const VARIANTS = {
  enter(direction: Direction) {
    return { zIndex: 0, x: `${direction * 100}%` };
  },
  center: { zIndex: 1, x: 0 },
  exit(direction: Direction) {
    return { zIndex: 0, x: `${direction * -100}%` };
  },
};

/**
 * this comes from popmotion, which is a peer dep of framer-motion, so it's technically already installed, but eslint isnt happy because its not in our package.json, instead of adding it, we can just copy it as is.
 * @see https://github.com/Popmotion/popmotion/blob/master/packages/popmotion/src/utils/wrap.ts
 */
export function wrap(min: number, max: number, v: number) {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
}

/**
 * @see https://codesandbox.io/s/framer-motion-image-gallery-pqvx3?from-embed=&file=/src/Example.tsx
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accommodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export function Carousel({ children }: { children: React.ReactNode }) {
  const [[page, direction], setPage] = React.useState([0, 0]);
  const count = React.Children.count(children);
  const dots = [...Array(count).keys()];

  // this matches tailwind's `sm` breakpoint
  const canSwipe = !useMedia('(min-width: 640px)');

  const paginate = (newDirection: number) => {
    return setPage(old => {
      const [oldPage] = old;
      return [oldPage + newDirection, newDirection];
    });
  };

  const cardIndex = wrap(0, React.Children.count(children), page);
  const child = React.Children.toArray(children)[cardIndex];

  return (
    <div className="relative">
      <button
        type="button"
        className="absolute top-[100px] left-8 z-10 hidden h-10 w-10 place-content-center rounded-full bg-black/40 sm:grid pga-slider-btn"
        onClick={() => paginate(-1)}
      >
        <span className="sr-only">go to previous</span>
        <Chevron className="-ml-1 h-6 w-6 rotate-180 transform text-surface-secondary" />
      </button>

      <div className="relative flex overflow-hidden pb-4">
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={cardIndex}
            custom={direction}
            variants={VARIANTS}
            initial="enter"
            animate="center"
            exit="exit"
            className="h-full w-full flex-shrink-0 px-4"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
            }}
            drag={canSwipe ? 'x' : false}
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(_event, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            {child}
          </motion.div>
        </AnimatePresence>
      </div>

      <button
        type="button"
        className="absolute top-[100px] right-8 z-10 hidden h-10 w-10 place-content-center rounded-full bg-black/40 sm:grid pga-slider-btn"
        onClick={() => paginate(1)}
      >
        <span className="sr-only">go to next</span>
        <Chevron className="h-6 w-6 text-surface-secondary" />
      </button>

      <div className="flex justify-center space-x-3">
        {dots.map(dot => (
          <button
            key={dot}
            type="button"
            className={clsx(
              'h-2 w-2 rounded-full',
              dot === cardIndex
                ? 'bg-utility-accent'
                : 'border-2 border-surface-secondary'
            )}
            onClick={() => setPage(old => [dot, old[1]])}
          />
        ))}
      </div>
    </div>
  );
}
