import Image from "next/legacy/image";

import { VideoPlayer } from '../video-player';

import breakdownUrl from 'public/images/billbreakdown.jpg';
import {componentsJson} from "~/constants/components";

export const BillBreakdown = () => {
    const { billBreakdownText3, billBreakdownText, billBreakdownText2 } = componentsJson.BillManagement.BillBreakdown
    return (
        <div className="flex h-full flex-col rounded-lg bg-white shadow-card">
            <Image
                src={breakdownUrl.src}
                alt=""
                className="h-full w-full rounded-t-lg"
                width={breakdownUrl.width}
                height={breakdownUrl.height}
            />
            <div className="p-5">
                <p className="text-2xl font-semibold text-black/90">{billBreakdownText}</p>
                <p className="mt-1 text-sm text-black/60">
                    {billBreakdownText2}
                </p>
                <div className="pt-4">
                    <VideoPlayer id={billBreakdownText3.value} displayText={billBreakdownText3.label} />
                </div>
            </div>
        </div>
    );
}
