import * as React from 'react';

import { useApp } from './context';

import { useWebAnalytics } from '~/utils/webAnalytics';
import SupportIcon from 'public/icons/support.svg';
import ChevronIcon from 'public/icons/chevron.svg';
import {componentsJson} from "~/constants/components";

type item = {
  title: string;
  link: string;
  tag: string;
  action?: VoidFunction;
};

const SupportCenter = () => {
  const { accountNumber } = useApp();

  const {supportsText, supportsText2} = componentsJson.BillManagement.SupportCenter

  const items = React.useMemo(() => {
    const itemsArray: Array<item> = [
      {
        title: supportsText.label,
        link: `${supportsText.value}${accountNumber}`,
        tag: supportsText.tag,
      },
    ];

    return itemsArray;
  }, [accountNumber]);

  const webAnalytics = useWebAnalytics();

  return (
    <div className="rounded-lg bg-offWhite-100 p-6 shadow-card">
      <SupportIcon className="mx-auto h-28" role="presentation" />
      <p className="mt-4 text-base tracking-[0.005em] text-black text-opacity-60">
        {supportsText2}
      </p>
      <ul className="mt-6 divide-y divide-border border-t border-border">
        {items.map(item => (
          <li key={item.title}>
            <a
              href={item.link.length ? item.link : undefined}
              onClick={() => {
                void webAnalytics({
                  EventTag: item.tag,
                  EventType: 'cta_click',
                });
                if (item.action) {
                  item.action();
                }
              }}
              target={item.tag == 'reportaproblem' ? '_blank' : '_self'}
              rel="noreferrer nofollow noopener"
              className={`${item.tag} flex cursor-pointer items-center justify-between py-4`}
            >
              <span className="text-sm font-medium tracking-[-0.005em] text-black text-opacity-90">
                {item.title}
              </span>
              <ChevronIcon className="h-4 text-gray-1000" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { SupportCenter };
