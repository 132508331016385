import Image from "next/legacy/image";

import { CTA } from '../cta';

import windowImageUrl from 'public/images/leaky-windows.png';
import {componentsJson} from "~/constants/components";

export const LeakyWindows = () => {

    const { leakyWindowsText3, leakyWindowsText2, leakyWindowsText} = componentsJson.BillManagement.LeakyWindows

    return (
        <div className="flex h-full flex-col rounded-lg bg-white shadow-card">
            <Image
                src={windowImageUrl.src}
                alt=""
                className="h-full w-full rounded-t-lg"
                width={windowImageUrl.width}
                height={windowImageUrl.height}
            />
            <div className="p-5">
                <p className="text-2xl font-semibold text-black/90">
                    {leakyWindowsText}
                </p>
                <p className="mt-1 text-sm text-black/60">
                    {leakyWindowsText2}
                </p>
                <div className="pt-4">
                    <CTA
                        as="externalLink"
                        href={leakyWindowsText3.value}
                        target="_blank"
                    >
                        {leakyWindowsText3.label}
                    </CTA>
                </div>
            </div>
        </div>
    );
}
