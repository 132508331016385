import * as React from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface Props {
  show: boolean;
  onClose: VoidFunction;
  title: string;
  actions: React.ReactNode;
}

const Modal: React.FC<Props> = ({
  children,
  show,
  onClose,
  title,
  actions,
}) => (
  <Transition show={show} as={React.Fragment}>
    <Dialog open={show} onClose={onClose} static>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center px-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </Dialog.Overlay>
          </Transition.Child>

          <Transition.Child
            enter="ease-out transform duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in transform duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="w-full sm:flex sm:justify-center"
          >
            <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-card transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:flex-col sm:items-start">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2 w-full text-sm text-gray-500">
                    {children}
                  </div>
                </div>
              </div>
              <div className="flex justify-end space-x-4 px-4 py-3 sm:px-6">
                {actions}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export { Modal };
