import Image from "next/legacy/image";

import { CTA } from '../cta';

import lightImageUrl from 'public/images/upgrade-lightbulbs.png';
import {componentsJson} from "~/constants/components";

export const LightUpgrade = () => {

    const { lightUpgradeText3, lightUpgradeText2, lightUpgradeText} = componentsJson.BillManagement.LightUpgrade

    return (
        <div className="flex h-full flex-col rounded-lg bg-white shadow-card">
            <Image
                src={lightImageUrl.src}
                alt=""
                className="h-full w-full rounded-t-lg"
                width={lightImageUrl.width}
                height={lightImageUrl.height}
            />
            <div className="p-5">
                <p className="text-2xl font-semibold text-black/90">
                    {lightUpgradeText}
                </p>
                <p className="mt-1 text-sm text-black/60">
                    {lightUpgradeText2}
                </p>
                <div className="pt-4">
                    <CTA
                        as="externalLink"
                        href={lightUpgradeText3.value}
                        target="_blank"
                    >
                        {lightUpgradeText3.label}
                    </CTA>
                </div>
            </div>
        </div>
    );
}
