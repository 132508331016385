import Image from "next/legacy/image";

import { CTA } from '../cta';
import { useApp } from '../context';

import billsimImageElectric from 'public/images/bill-simulator.png';
import billsimImageGas from 'public/images/bill-sim-gas-only.png';
import {componentsJson} from "~/constants/components";

export function BillSimulator() {
  const { fuelType } = useApp();
  const billsimImage =
    fuelType == 'gas' ? billsimImageGas : billsimImageElectric;

  const { billSimulatorText, billSimulatorText2, billSimulatorText3} = componentsJson.BillManagement.BillSimulator

  return (
    <div className="flex h-full flex-col rounded-lg bg-white shadow-card">
      <Image
        src={billsimImage.src}
        alt=""
        className="h-full w-full rounded-t-lg"
        width={billsimImage.width}
        height={billsimImage.height}
      />
      <div className="p-5">
        <p className="text-2xl font-semibold text-black/90">{billSimulatorText}</p>
        <p className="mt-1 text-sm text-black/60">
            {billSimulatorText2}
        </p>
        <div className="mt-4">
          <CTA
            as="externalLink"
            href="/bill-simulator"
            target={process.env.ENV === 'production' ? '_blank' : 'self'}
          >
              {billSimulatorText3}
          </CTA>
        </div>
      </div>
    </div>
  );
}
