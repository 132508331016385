import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

import { useApp } from './context';

import { useWebAnalytics } from '~/utils/webAnalytics';
import Upsell from 'public/icons/home-profile/upsell.svg';
import DetachedSingleFamily from 'public/icons/home-profile/detached-single-family.svg';
import MultiUnit from 'public/icons/home-profile/multi-unit.svg';
import AttachedSingleFamily from 'public/icons/home-profile/attached-single-family.svg';
import Other from 'public/icons/home-profile/other.svg';
import Thermostat from 'public/icons/thermostat.svg';
import Refrigerator from 'public/icons/refrigerator.svg';
import Washer from 'public/icons/washer.svg';
import Light from 'public/icons/light.svg';
import { fetchUpdateHomeCharacteristics } from '~/lib/fetch-homecharacteristics';
import {componentsJson} from "~/constants/components";

interface HomeTypeInputProps {
  value: string;
  display: string;
  icon: React.ReactNode;
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
}

const HomeTypeInput: React.VFC<HomeTypeInputProps> = ({
  display,
  icon,
  value,
  name,
  checked,
  onChange,
}) => (
  <label className="block">
    <input
      type="radio"
      name={name}
      value={value}
      className="peer hidden"
      onChange={onChange}
      checked={checked}
    />
    <span className="flex cursor-pointer items-center rounded-lg bg-offWhite-100 py-2 px-4 text-sm font-medium tracking-[-0.005em] text-blue-100 peer-checked:bg-blue-200 peer-checked:text-white">
      {icon}
      <span>{display}</span>
    </span>
  </label>
);
interface Props {
  step?: 0 | 1 | 2 | 3;
  homeProfileVersion?: string | unknown | undefined;
  traceparent: string;
  xraytraceid: string;
}

const HomeProfile: React.VFC<Props> = ({
  step: initialStep = 0,
  homeProfileVersion,
  traceparent,
  xraytraceid,
}) => {
  const [step, setStep] = React.useState<0 | 1 | 2 | 3>(initialStep);
  const { serviceNetworkId, customerId, customerSiteId } = useApp();
  const [homeType, setHomeType] = React.useState<string>('');
  const [homeSize, setHomeSize] = React.useState<string>('');
  const [countPeople, setCountPeople] = React.useState<string>('');
  const webAnalytics = useWebAnalytics();

  const {homeProfileText2, homeProfileText4, homeProfileText, homeProfileText1, homeProfileText5,
  homeProfileText8, homeProfileText6, homeProfileText7, homeProfileText9, homeProfileText10, homeProfileText11,
      homeProfileText13, homeProfileText12, homeProfileText14, homeProfileText15, homeProfileText16, homeProfileText17} = componentsJson.BillManagement.HomeProfile

  return (
    <div className="overflow-hidden rounded-lg bg-white py-10 shadow-card">
      <form
        method="post"
        onSubmit={async event => {
          event.preventDefault();

          if (step === 0 && !homeType) return undefined;
          if (step === 1 && !homeSize) return undefined;
          if (step === 2 && !countPeople) return undefined;

          if (step === 0 || step === 1) {
            void webAnalytics({
              EventTag: 'homeprofile',
              EventType: 'cta_click_next',
            });
          } else if (step === 2) {
            void webAnalytics({
              EventTag: 'homeprofile',
              EventType: 'cta_click_showmybreakdownestimate',
            });
          }

          if (step !== 2) {
            return setStep(o => {
              if (o === 2) return 3;
              if (o === 1) return 2;
              if (o === 0) return 1;
              return o;
            });
          }

          try {
            await fetchUpdateHomeCharacteristics({ serviceNetworkId, customerId, customerSiteId },
                                                    homeType,homeSize,countPeople, traceparent, xraytraceid, homeProfileVersion);

            return setStep(3);
          } catch (error: unknown) {
            return console.error(error);
          }
        }}
      >
        <AnimatePresence>
          {step < 3 ? (
            <div>
              <div className="px-6">
                <h3 className="pb-1 text-xl font-semibold text-black text-opacity-90">
                    {homeProfileText17}
                </h3>
                <p className="text-sm text-black text-opacity-60">
                    {homeProfileText16}
                </p>
              </div>

              <div className="flex overflow-hidden">
                <motion.div
                  className="w-full flex-shrink-0 px-6"
                  animate={{ x: `${step * 100 * -1}%` }}
                >
                  <div className="pt-4 pb-4 text-sm font-medium leading-none text-black text-opacity-90">
                      {homeProfileText15}
                  </div>
                  <div className="space-y-1.5">
                    <HomeTypeInput
                      value={homeProfileText14}
                      icon={<DetachedSingleFamily className="mr-4 h-10 w-9" />}
                      display="Detached Single-Family"
                      name="HomeType"
                      onChange={() => setHomeType('Detached Single-Family')}
                      checked={homeType === 'Detached Single-Family'}
                    />
                    <HomeTypeInput
                      value={homeProfileText13}
                      icon={<MultiUnit className="mr-4 h-10 w-9" />}
                      display="Multi-Unit"
                      name="HomeType"
                      onChange={() => setHomeType('Multi-Unit')}
                      checked={homeType === 'Multi-Unit'}
                    />
                    <HomeTypeInput
                      value={homeProfileText12}
                      icon={<AttachedSingleFamily className="mr-4 h-10 w-9" />}
                      display="Attached Single-Family"
                      name="HomeType"
                      onChange={() => setHomeType('Attached Single-Family')}
                      checked={homeType === 'Attached Single-Family'}
                    />
                    <HomeTypeInput
                      value={homeProfileText11}
                      icon={<Other className="mr-4 h-10 w-9" />}
                      display="Other"
                      name="HomeType"
                      onChange={() => setHomeType('Other')}
                      checked={homeType === 'Other'}
                    />
                  </div>
                </motion.div>

                <motion.div
                  className="w-full flex-shrink-0 px-6"
                  animate={{ x: `${step * 100 * -1}%` }}
                >
                  <div className="pt-4 pb-7 text-sm font-medium leading-none text-black text-opacity-90">
                      {homeProfileText10}
                  </div>
                  <input
                    pattern="[0-9]+"
                    className="w-full rounded border border-border p-4"
                    inputMode="decimal"
                    type="text"
                    name="HomeSize"
                    value={homeSize}
                    onChange={event => {
                      setHomeSize(old => {
                        if (!event.target.value) return '';
                        const value = event.target.value.replace(/[^0-9]/g, '');
                        if (!value) return old;
                        return value;
                      });
                    }}
                  />
                </motion.div>

                <motion.div
                  className="w-full flex-shrink-0 px-6"
                  animate={{ x: `${step * 100 * -1}%` }}
                >
                  <div className="pt-4 pb-7 text-sm font-medium leading-none text-black text-opacity-90">
                      {homeProfileText9}
                  </div>
                  <input
                    pattern="[0-9]*"
                    className="w-full rounded border border-border p-4"
                    inputMode="decimal"
                    type="text"
                    name="CountPeople"
                    value={countPeople}
                    onChange={event => {
                      setCountPeople(old => {
                        if (!event.target.value) return '';
                        const value = event.target.value.replace(/[^0-9]/g, '');
                        if (!value) return old;
                        return value;
                      });
                    }}
                  />
                </motion.div>
              </div>

              <div className="px-6">
                <div className="flex items-center justify-center space-x-2.5 pt-7 pb-6">
                  <span
                    className={clsx(
                      'h-2.5 w-2.5 rounded-full',
                      step === 0 ? 'bg-blue-200' : 'bg-silver-100'
                    )}
                  />
                  <span
                    className={clsx(
                      'h-2.5 w-2.5 rounded-full',
                      step === 1 ? 'bg-blue-200' : 'bg-silver-100'
                    )}
                  />
                  <span
                    className={clsx(
                      'h-2.5 w-2.5 rounded-full',
                      step === 2 ? 'bg-blue-200' : 'bg-silver-100'
                    )}
                  />
                </div>
                <button
                  type="submit"
                  className="block w-full rounded-full bg-utility-button px-5 py-4 text-base font-semibold text-white"
                >
                  {step === 2 ? homeProfileText7 : homeProfileText8}
                </button>
              </div>
            </div>
          ) : (
            <motion.div
              className="w-full px-6"
              initial={{ x: '100%' }}
              animate={{ x: '0%' }}
            >
              <Upsell className="mx-auto mb-6 h-[200px] w-[200px]" />

              <h3 className="text-xl font-semibold tracking-[0.0015em] text-black text-opacity-90">
                  {homeProfileText6}
              </h3>

              <ul className="divide-y pt-6">
                <li className="flex items-center py-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-orange-100">
                    <Thermostat className="h-5 w-5 text-white" />
                  </div>
                  <span className="mx-4 flex-grow text-sm font-medium tracking-[-0.005em] text-black text-opacity-90">
                    {homeProfileText5}
                  </span>
                  <span className="text-sm font-medium tracking-[-0.005em] text-black text-opacity-60">
                    {homeProfileText}
                  </span>
                </li>
                <li className="flex items-center py-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-300">
                    <Refrigerator className="h-5 w-5 text-white" />
                  </div>
                  <span className="mx-4 flex-grow text-sm font-medium tracking-[-0.005em] text-black text-opacity-90">
                    {homeProfileText4}
                  </span>
                  <span className="text-sm font-medium tracking-[-0.005em] text-black text-opacity-60">
                    {homeProfileText}
                  </span>
                </li>
                <li className="flex items-center py-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-400">
                    <Washer className="h-5 w-5 text-white" />
                  </div>
                  <span className="mx-4 flex-grow text-sm font-medium tracking-[-0.005em] text-black text-opacity-90">
                    {homeProfileText2}
                  </span>
                  <span className="text-sm font-medium tracking-[-0.005em] text-black text-opacity-60">
                    {homeProfileText}
                  </span>
                </li>
                <li className="flex items-center py-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gold-100">
                    <Light className="h-5 w-5 text-white" />
                  </div>
                  <span className="mx-4 flex-grow text-sm font-medium tracking-[-0.005em] text-black text-opacity-90">
                    {homeProfileText1}
                  </span>
                  <span className="text-sm font-medium tracking-[-0.005em] text-black text-opacity-60">
                    {homeProfileText}
                  </span>
                </li>
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </div>
  );
};

export { HomeProfile };
