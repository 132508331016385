import React from 'react';

import ModalVideo from 'react-modal-video'

interface VideoPlayerProps {
  id: string | undefined;
  displayText: string | undefined;
}

const VideoPlayer: React.VFC<VideoPlayerProps> = ({ id, displayText }) => {
  const [isOpen, setIsOpen] = React.useState(false); 

  return (
    <>
      <button
        className="block w-full rounded-full bg-utility-button px-8 py-3 text-center font-semibold leading-5 text-white pga-watch-video"
        onClick={() => setIsOpen(true)}
      >
        <span>{displayText}</span>
      </button>
      <ModalVideo channel='custom' isOpen={isOpen} autoplay={1} url={id} onClose={() => setIsOpen(false)} />      
    </>
  );  
};

export { VideoPlayer };
