import * as React from 'react';
import type { LinkProps as NextLinkProps } from 'next/link';
import Link from 'next/link';

interface BaseProps {
  children: React.ReactNode;
}

interface ButtonProps
  extends BaseProps,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> {
  as: 'button';
}

interface LinkProps extends BaseProps, Omit<NextLinkProps, keyof BaseProps> {
  as: 'a';
}

interface ExternalLinkProps
  extends BaseProps,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps> {
  as: 'externalLink';
}

type Props = ButtonProps | LinkProps | ExternalLinkProps;

const className =
  'px-8 py-3 block w-full font-semibold leading-5 text-center text-white rounded-full bg-utility-button';

export function CTA(props: Props) {
  switch (props.as) {
    case 'a': {
      return (
        <Link legacyBehavior {...props}>
          <a className={className}>{props.children}</a>
        </Link>
      );
    }

    case 'button': {
      return (
        <button className={className} {...props}>
          {props.children}
        </button>
      );
    }

    case 'externalLink': {
      return (
        <a className={className} {...props}>
          {props.children}
        </a>
      );
    }

    default: {
      // @ts-expect-error - we're covering our butts
      throw new Error(`Unhandled \`as\` type - received \`${props.as}\` `);
    }
  }
}
