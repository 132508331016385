export const pagesJson = {
    "Logout": {
        logoutText: "Analyze My Bill - Logout",
        traceparentText: "traceparent",
        xAmznTraceIdText: "x-amzn-trace-id",
        logoutDestination: `https://${process.env.AZURE_DOMAIN_HINT}?signout=true`,
        xHostText: "X-Forwarded-Host",
        xPhoto: "X-Forwarded-Proto",
        dismissedBillDisclaimerText: "dismissedBillDisclaimer"
    },
    "Login": {
        callbackrouteText: "callbackroute",
        azureAdB2cText:"azure-ad-b2c",
        titleText: "Analyze My Bill - Login",
        xAmznTraceIdText: "x-amzn-trace-id",
        traceparentText: "traceparent",
    },
    "InternalServerError": {
        statusTitle: "Status code 500",
        analyzeText2:"Analyze My Bill",
        dteText: {
            label:"Visit DTEEnergy.com",
            value: "https://newlook.dteenergy.com"
        },
        internalText: "We were unable to locate an active account associated with this address.",
        internalText2: "Please close this window.",
        internalText3: "Status code: 500",
        internalBackgroundText: "#fefefe",
    },
    "IndexPages": {
        indexPagesText: "Your DTE Energy account is not eligible to use this part of the Bill Analyzer tool. We apologize for any inconvenience.",
        indexPagesTitle: "Dashboard • Analyze My Bill",
        traceparentText: "traceparent",
        xAmznTraceIdText: "x-amzn-trace-id",
    },
    "BillHistoryIndex": {
        billHistoryText: "Bill History • Analyze My Bill",
        traceparentText: "traceparent",
        xAmznTraceIdText: "x-amzn-trace-id",
        analyzeBillHistory: "← Analyze My Bill",
        billHistoryText2: "Bill History",
        billHistoryText3: "Total: ",
        billHistoryBackground: "#fefefe"
    },
    "EndDateBillHistory": {
        endDateBillHistoryBackground: "#fefefe",
        endDateBillHistoryText: "Learn More About Your",
        endDateBillHistoryText2: "Bill",
        endDateBillHistoryText3: "Bill Contributors",
        endDateBillHistoryText4: "No change from previous period",
        endDateBillHistoryText5: "from previous period",
        endDateBillHistoryText6: "Go to next bill",
        endDateBillHistoryText7: "← Bill History",
        endDateBillHistoryText8: "Analyze My Bill",
        endDateBillHistoryText9: "Couldn't find a bill with requested start/end date",
        traceparentText: "traceparent",
        xAmznTraceIdText: "x-amzn-trace-id",
    }
}
