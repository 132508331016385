import * as React from 'react';
import Head from 'next/head';
import {
  parseISO,
  addDays,
  differenceInCalendarDays,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import dynamic from 'next/dynamic';
import isWV from 'is-ua-webview';
import { useRouter } from 'next/router';
import type { GetServerSideProps, NextPage } from 'next';
// import { useQuery } from '@tanstack/react-query';

import { useWebAnalytics } from '../utils/webAnalytics';

import {
  getPortalOrAzureUser,
  getSpecifiedSite,
} from './api/auth/[...nextauth]';

import { HTTPError } from '~/lib/fetcher';
import { SupportCenter } from '~/components/support-center';
import { roundToDollar } from '~/lib/format-money';
import { getWaysToSave } from '~/lib/ways-to-save';
import { UpsellABTest } from '~/components/upsell';
import { mapBillContributorData } from '~/lib/map-bill-contributor-data';
import { typedBoolean } from '~/utils/filter';
import type { BarData } from '~/components/bill-projection-header';
import type { Contributor } from '../components/bill-contributors';
import type { WayToSave as WayToSaveType } from '../lib/ways-to-save';
import type { BasePageProps } from '../types/base-page';
import type {
  BillImpact,
  BillingSummaryAPIResponse,
  BillingUsageThing,
  BillingUsageUsage,
  CustomerFindAPIResponse,
} from '../types/brainstem-responses';
import { CardTitle } from '~/components/card-title';
import { MissingSites } from '~/components/missing-sites';
import { siteStorage } from '~/lib/sessions';
import pkgJSON from 'package.json';
import { HomeProfile } from '~/components/home-profile';
import type { UsageFilterAPIRequest } from '~/types/brainstem-requests';
import {
  BillBreakdown,
  BillHistory,
  BillSimulator,
  Carousel,
  DoYouSmellGas,
  EnergyUsage,
  FurnaceBoost,
  LeakyWindows,
  LightUpgrade,
} from '../components/carousel';
import { makeGuesstimate } from '../utils/make-guesstimate';
import { findRateGroupThings, SortedPeakData, sortPeakData } from '../components/peak-usage';
import { Modal } from '../components/modal';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
// import { fetchEligibility } from '../simulator/lib/fetch-eligibility';

import { billManagementJson } from '~/constants/bill-management';

import {
  fetchCachedBillContributors, fetchCachedBillImpacts, fetchCachedBillingSummary,
  fetchCachedCustomer, fetchCachedDates, fetchCachedEligibilitySSR, fetchCachedHomeProfile, fetchCachedPeakUsage
} from '../lib/cache';
import { fetchMetaRateGroups } from '../lib/fetch-metadata-rategroups';

const { version } = pkgJSON;
import AppData from "public/bill-management/data.json";
import { RateCompareEligibilityApiResponse } from '~/types/billsim-responses';
import { context, defaultTextMapSetter, SpanStatusCode, trace, SpanKind } from '@opentelemetry/api';
import { tracer } from '~/utils/telemetry/OTEL-instrumentation';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { AWSXRayPropagator } from '@opentelemetry/propagator-aws-xray';
import { SemanticAttributes } from '@opentelemetry/semantic-conventions';
import { Fragment } from "react";
import { pagesJson } from "~/constants/pages";

const { homepage: {
  attributes: {
    hasApplicationOverview,
    hasWaysToSave } } } = AppData;

/* Conditionally Rendered Components */
const BillContributors = dynamic(async () => {
  const m = await import('~/components/bill-contributors');
  return m.BillContributors;
});

const PeakUsage = dynamic(async () => {
  const m = await import('~/components/peak-usage');
  return m.PeakUsage;
}, { ssr: false });
const GatheringUsage = dynamic(async () => {
  const m = await import('~/components/gathering-usage');
  return m.GatheringUsage;
});
const UnknownPremise = dynamic(async () => {
  const m = await import('~/components/unknown-premise');
  return m.UnknownPremise;
});
const ReturnToDTE = dynamic(async () => {
  const m = await import('~/components/return-to-dte');
  return m.ReturnToDTE;
});
const BillFactorsCard = dynamic(async () => {
  const m = await import('~/components/bill-impacts');
  return m.BillFactorsCard;
});
const TemporarilyUnavailable = dynamic(async () => {
  const m = await import('~/components/temporarily-unavailable');
  return m.TemporarilyUnavailable;
});
const ShepherdComponent = dynamic(async () => {
  const m = await import('../components/intro-tour');
  return m.default;
})

const BillProjectionHeader = dynamic(async () => {
  const m = await import('../components/bill-projection-header');
  return m.BillProjectionHeader;
}, { ssr: false })


interface UserRouteData extends BasePageProps {
  billContributors: Array<Contributor> | null;
  billImpacts: Array<BillImpact> | null;
  isLiteUser?: boolean;
  projectionHeaderData: Array<BarData>;
  waysToSave: Array<WayToSaveType> | null;
  firstLogin?: never;
  statusCode?: never;
  shouldRedirectToLogin?: never;
  unknownPremise?: never;
  returnToDTE?: never;
  noBillingData?: never;
  showHomeProfileQuestions: boolean;
  currentUsage?: UsageFilterAPIRequest;
  TOUthings?: CustomerFindAPIResponse['sites'][0]['things'];
  isAmrUser?: boolean;
  peakUsages: SortedPeakData | null;
  traceparent: string;
  xraytraceid: string;
  eligibilityData: RateCompareEligibilityApiResponse | null;
}

interface FirstLoginRouteData extends BasePageProps {
  billContributors?: never;
  billImpacts?: never;
  isLiteUser?: never;
  projectionHeaderData?: never;
  waysToSave?: never;
  firstLogin: true;
  statusCode?: never;
  shouldRedirectToLogin?: never;
  unknownPremise?: never;
  returnToDTE?: never;
  noBillingData?: never;
  showHomeProfileQuestions?: boolean;
  currentUsage?: UsageFilterAPIRequest;
  TOUthings?: CustomerFindAPIResponse['sites'][0]['things'];
  isAmrUser?: boolean;
  traceparent: string;
  xraytraceid: string;
  peakUsages?: SortedPeakData | null;
  eligibilityData?: never;
}

export interface ErrorRouteData extends BasePageProps {
  billContributors?: never;
  billImpacts?: never;
  currentUsage?: never;
  eligibilityData?: never;
  firstLogin?: never;
  isAmrUser?: boolean;
  isLiteUser?: never;
  nextBill?: never;
  noBillingData?: never;
  peakUsages?: never;
  projectionHeaderData?: never;
  returnToDTE?: never;
  shouldRedirectToLogin?: never;
  showHomeProfileQuestions?: never;
  statusCode: number;
  TOUthings?: never;
  traceparent: string;
  unknownPremise?: never;
  urlParams?: never;
  waysToSave?: never;
  xraytraceid: string;
  yearBill?: never;
}

interface NoSitesData extends BasePageProps {
  billContributors?: never;
  billImpacts?: never;
  isLiteUser?: never;
  projectionHeaderData?: never;
  waysToSave?: never;
  firstLogin?: never;
  statusCode: number;
  shouldRedirectToLogin?: never;
  unknownPremise?: never;
  returnToDTE?: never;
  noBillingData?: never;
  showHomeProfileQuestions?: never;
  currentUsage?: never;
  TOUthings?: never;
  isAmrUser?: boolean;
  peakUsages?: never;
  traceparent: string;
  xraytraceid: string;
  eligibilityData: never;
}

interface NoSessionRouteData extends BasePageProps {
  billContributors?: never;
  billImpacts?: never;
  currentUsage?: never;
  eligibilityData?: never;
  firstLogin?: never;
  isAmrUser?: boolean;
  isLiteUser?: never;
  noBillingData?: never;
  peakUsages?: never;
  projectionHeaderData?: never;
  returnToDTE?: never;
  shouldRedirectToLogin: true;
  showHomeProfileQuestions?: never;
  statusCode?: never;
  TOUthings?: never;
  traceparent: string;
  unknownPremise?: never;
  waysToSave?: never;
  xraytraceid?: string;
}

interface UnknownPremiseData extends BasePageProps {
  billContributors?: never;
  billImpacts?: never;
  isLiteUser?: never;
  projectionHeaderData?: never;
  waysToSave?: never;
  firstLogin?: never;
  statusCode?: never;
  shouldRedirectToLogin?: never;
  unknownPremise: true;
  returnToDTE?: never;
  noBillingData?: never;
  showHomeProfileQuestions?: never;
  currentUsage?: never;
  TOUthings?: never;
  isAmrUser?: boolean;
  peakUsages?: never;
  traceparent: string;
  xraytraceid: string;
  eligibilityData?: never;
}

interface ReturnToDTEProps extends BasePageProps {
  billContributors?: never;
  billImpacts?: never;
  currentUsage?: never;
  eligibilityData?: never;
  firstLogin?: never;
  isLiteUser?: never;
  isAmrUser?: boolean;
  noBillingData?: never;
  peakUsages?: never;
  projectionHeaderData?: never;
  returnToDTE: true;
  showHomeProfileQuestions?: never;
  shouldRedirectToLogin?: never;
  statusCode?: never;
  TOUthings?: never;
  traceparent: string;
  unknownPremise?: never;
  waysToSave?: never;
  xraytraceid?: string;
}

export interface NoBillingData extends BasePageProps {
  billContributors?: never;
  billImpacts?: never;
  eligibilityData?: never;
  isLiteUser?: never;
  projectionHeaderData?: never;
  waysToSave?: never;
  firstLogin?: never;
  statusCode?: never;
  shouldRedirectToLogin?: never;
  unknownPremise?: never;
  returnToDTE?: never;
  noBillingData: true;
  showHomeProfileQuestions?: never;
  currentUsage?: never;
  TOUthings?: never;
  urlParams?: never;
  yearBill?: never;
  nextBill?: never;
  isAmrUser?: boolean;
  peakUsages?: never;
  traceparent: string;
  xraytraceid: string;
}

type RouteData =
  | ErrorRouteData
  | FirstLoginRouteData
  | NoBillingData
  | NoSessionRouteData
  | NoSitesData
  | ReturnToDTEProps
  | UnknownPremiseData
  | UserRouteData

// export const touCustomerRateCodes = ['EFR_D1_8', 'EFR_D1_2'];
export const peakUsageEnabled = true;
export const rateChangeBannerEnabled = true;

export const getFuelSummary = (billSummary: BillingSummaryAPIResponse | undefined, fuel: 'Gas' | 'Electric' | 'Usage') =>
  billSummary?.sites[0].things.find(thing => thing.uuid == `${fuel} summary`);

export const getFuelUsage = (usageThing: BillingUsageThing | undefined, label: BillingUsageUsage['label']) =>
  usageThing?.usage.find((usage) => usage.label === label);

//@ts-expect-error
const getServerSideProps: GetServerSideProps<RouteData> = async ({
  req,
  res,
  query,
}) => {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=60, stale-while-revalidate=300'
  )

  let span;
  let traceparent;
  let xraytraceid;

  try {
    const userAgent = req.headers['user-agent'];
    const isWebView = userAgent ? isWV(userAgent) : false;

    span = tracer.startSpan('BillMgmt::' + process.env.ENV + '::Analyze My Bill',
      { kind: SpanKind.SERVER });

    span.setAttribute([SemanticAttributes.HTTP_METHOD], 'GET');
    span.setAttribute([SemanticAttributes.HTTP_URL], req.url);
    span.setAttribute([SemanticAttributes.HTTP_USER_AGENT], userAgent);
    span.setAttribute('PageName', 'Analyze My Bill');

    const propagator = new W3CTraceContextPropagator();
    let carrier: { [key: string]: unknown } = {};

    propagator.inject(
      trace.setSpanContext(context.active(), span.spanContext()),
      carrier,
      defaultTextMapSetter
    );

    traceparent = carrier.traceparent;
    res.setHeader('traceparent', traceparent);

    carrier = {};

    const xraypropigator = new AWSXRayPropagator();
    xraypropigator.inject(
      trace.setSpanContext(context.active(), span.spanContext()),
      carrier,
      defaultTextMapSetter
    );

    xraytraceid = carrier['x-amzn-trace-id'];
    res.setHeader('x-amzn-trace-id', xraytraceid);

    if (query.contractAccountId) {
      const cookieSession = await siteStorage.getSession(req.headers.cookie);
      // flash will set the cookie until the next `get`, just make sure to commit the session after you call `get`
      cookieSession.flash('contractAccountId', query.contractAccountId);
      res.setHeader('Set-Cookie', await siteStorage.commitSession(cookieSession));
    }

    let [sessionMS, session] = await getPortalOrAzureUser(req, res, query, true);

    const callbackroute = getCookie('callbackroute', { req, res });
    if (callbackroute) {
      deleteCookie('callbackroute', { req, res });

      return {
        redirect: {
          destination: callbackroute,
          statusCode: 302,
        },
      };
    }

    // console.log('session', session);
    // console.log('------')
    // console.log('session user sites', session?.user.customerSites);

    // 1. We don't have a user session
    if (!session) {
      console.error(`NO SESSION`, session);

      const props: NoSessionRouteData = {
        shouldRedirectToLogin: true,
        accountNumber: '',
        customerId: -1,
        customerSiteId: -1,
        customerSites: [],
        fuelType: 'electric',
        isWebView,
        serviceNetworkId: -1,
        timeZone: 'America/New_York',
        utility: 'dtei',
        session,
        traceparent,
      };

      return { props };
    }

    // 2. We ran into an issue calling our session callback
    if (session.user.error) {
      const props: ReturnToDTEProps = {
        returnToDTE: true,
        accountNumber: '',
        customerId: -1,
        customerSiteId: -1,
        customerSites: [],
        fuelType: 'electric',
        isWebView,
        serviceNetworkId: -1,
        session,
        timeZone: 'America/New_York',
        utility: 'dtei',
        traceparent,
      };

      return { props };
    }

    // 3. We didn't have an error, but somehow don't have an account number
    if (!session.user.accountNumber) {
      console.error(`NO SESSION ACCOUNT NUMBER`, session);

      return {
        redirect: {
          destination: '/login',
          statusCode: 302,
        },
      };
    }

    const {
      accountNumber,
      serviceNetworkId,
      customerId,
      customerSiteId,
      customerSites,
      externalSiteId,
      utility,
      timeZone,
      fuelType,
      isLiteUser,
      isAmrUser,
    } = await getSpecifiedSite(req, session.user);

    try {
      // Get Billing Dates
      const billingDates = await fetchCachedDates({
        serviceNetworkId,
        customerAccountNumber: accountNumber,
      }, traceparent, xraytraceid);

      const latestDate = billingDates[billingDates.length - 1];


      const parsedCurrentCycleEnd = toZonedTime(
        parseISO(latestDate.endDate),
        timeZone
      );

      const estimatedCycleStart = addDays(parsedCurrentCycleEnd, 1);
      const estimatedCycleEnd = addDays(parsedCurrentCycleEnd, 30);

      const now = new Date();
      const daysLeftInCycle =
        differenceInCalendarDays(estimatedCycleEnd, now) + 1;

      const isPendingBill = daysLeftInCycle <= 0;

      // const parsedStart = parseISO(
      //   toZonedTime(latestDate.startDate, timeZone).toISOString()
      // );

      // const parsedEnd = parseISO(
      //   toZonedTime(latestDate.endDate, timeZone).toISOString()
      // );

      const homeProfileDataFetch = fetchCachedHomeProfile({ serviceNetworkId, customerId, customerSiteId }, traceparent, xraytraceid);

      const customerFindFetch = fetchCachedCustomer({
        serviceNetworkId,
        customerAccountNumber: accountNumber,
      }, traceparent, xraytraceid);

      const billSummaryFetch = fetchCachedBillingSummary({
        IncludeCalculated: true,
        IncludeToday: false,
        CustomerAccountNumber: accountNumber,
        ServiceNetworkId: serviceNetworkId,
        Interval: {
          granularity: "billingperiod",
          Value: 1
        },
        startDate: latestDate.startDate,
        endDate: latestDate.endDate,
      }, traceparent, xraytraceid);

      // todo: add 5 blling dates length check to bill history pages?
      const billImpactsFetch = billManagementJson.BillManagement.BillImpacts.active && billingDates.length > 5 ? fetchCachedBillImpacts({
        serviceNetworkId,
        customerId,
        customerSiteId,
        startDate: latestDate.startDate,
        endDate: latestDate.endDate,
        // compareToAYearAgo: !!lastYearCycle,
        fuelType,
      }, traceparent, xraytraceid) : null;

      const billingContributorsDataFetch = billManagementJson.BillManagement.BillContributors.active ? fetchCachedBillContributors({
        serviceNetworkId,
        customerId,
        customerSiteId,
        startDate: latestDate.startDate,
        endDate: latestDate.endDate,
        fuelType,
      }, traceparent, xraytraceid) : null;

      const peakUsageDataFetch = billManagementJson.BillManagement.PeakUsage.active ? fetchCachedPeakUsage({
        StartDate: latestDate.startDate,
        EndDate: latestDate.endDate,
        CustomerAccountNumber: Number(accountNumber),
        ServiceNetworkId: serviceNetworkId,
        Interval: {
          granularity: 'billingperiod',
          Value: 1,
        },
      }, traceparent, xraytraceid) : null;

      const fetchEligibilityData = fetchCachedEligibilitySSR(accountNumber);

      // Gotta Fetch em all 🫱🏼
      const data = await Promise.allSettled([
        homeProfileDataFetch,
        customerFindFetch,
        billSummaryFetch,
        billImpactsFetch,
        billingContributorsDataFetch,
        peakUsageDataFetch,
        fetchEligibilityData
      ]);

      const homeProfileData = data[0].status == "fulfilled" ? data[0].value : undefined;
      const customerFind = data[1].status == "fulfilled" ? data[1].value : undefined;
      const billSummary = data[2].status == "fulfilled" ? data[2].value : undefined;
      const billImpacts = data[3].status == "fulfilled" ? data[3].value : null;
      const billingContributorsData = data[4].status == "fulfilled" ? data[4].value : null;
      const peakUsageData = data[5].status == "fulfilled" ? data[5].value : undefined;
      const eligibilityData = data[6].status == "fulfilled" && data[6]?.value ? data[6].value : null;


      // console.log('peakUsageData', (peakUsageData.sites[0].things[0]));
      // console.log('peakUsageData usage', (peakUsageData.sites[0].things[0].usage));
      // console.log('peakUsageData usage 0 cost container', (peakUsageData.sites[0].things[0].usage[0].costContainers));
      // console.log('billingContributorsData', billingContributorsData);

      const showHomeProfileQuestions = false;
      // !hasHomeProfileValueAndNotDefault(homeProfileHomeType) ||
      //   !hasHomeProfileValueAndNotDefault(homeProfileHomeSize) ||
      //   !hasHomeProfileValueAndNotDefault(homeProfileCountPeople);

      if (customerFind?.source !== 'BrainStem') {

        const gatheringUsageCookie = getCookie('gathering-usage-visit-count' + accountNumber, { req, res });
        const gatheringUsageVisitCount = Number(gatheringUsageCookie) || 0;

        setCookie('gathering-usage-visit-count' + accountNumber, String(gatheringUsageVisitCount + 1), {
          req,
          res,
          maxAge: 60 * 12 * 60, // 12 hours
          path: '/',
          sameSite: 'lax',
          secure: false,
        });

        const props: FirstLoginRouteData = {
          firstLogin: true,
          accountNumber,
          customerId,
          customerSiteId,
          customerSites,
          fuelType,
          isWebView,
          serviceNetworkId,
          session,
          timeZone,
          utility,
          showHomeProfileQuestions,
          traceparent,
          xraytraceid,
        };
        return { props };
      }

      const requestedSite = customerFind.sites.find(
        site =>
          site.customerSiteId === customerSiteId ||
          site.externalSiteId === externalSiteId
      );

      if (!requestedSite) {
        const props: UnknownPremiseData = {
          unknownPremise: true,
          accountNumber,
          customerId,
          customerSiteId,
          customerSites,
          fuelType,
          isWebView,
          serviceNetworkId,
          session,
          timeZone,
          utility,
          traceparent,
          xraytraceid,
        };

        return { props };
      }


      const electricUsage = getFuelSummary(billSummary, 'Electric');
      const gasUsage = getFuelSummary(billSummary, 'Gas');
      const totalUsage = getFuelSummary(billSummary, 'Usage');

      const yearAgoElectricBill = getFuelUsage(electricUsage, "YearAgoBill") ?? getFuelUsage(electricUsage, "PreviousBill");
      const currentElectricBill = getFuelUsage(electricUsage, "LastBill");
      const nextElectricBill = getFuelUsage(electricUsage, "BillSoFar");
      const forecastedElectricBill = electricUsage?.forecast?.find((usage) => usage.label === "Projected");

      const yearAgoGasBill = gasUsage?.usage.find((usage) => usage.label === "YearAgoBill") ?? getFuelUsage(gasUsage, "PreviousBill");
      const currentGasBill = gasUsage?.usage.find((usage) => usage.label === "LastBill");
      const nextGasBill = gasUsage?.usage.find((usage) => usage.label === "BillSoFar");
      const forecastedGasBill = gasUsage?.forecast?.find((usage) => usage.label === "Projected");

      const yearAgoTotalBill = getFuelUsage(totalUsage, "YearAgoBill") ?? getFuelUsage(totalUsage, "PreviousBill");
      const yearAgoReference = getFuelUsage(totalUsage, "YearAgoBill");
      const currentTotalBill = getFuelUsage(totalUsage, "LastBill");
      const nextTotalBill = getFuelUsage(totalUsage, "BillSoFar");
      const forecastedTotalBill = totalUsage?.forecast?.find((usage) => usage.label === "Projected");

      // console.log('forecastedTotalBill', forecastedTotalBill);
      // console.log('forecastedElectricBill', forecastedElectricBill);
      // console.log('forecastedGasBill', forecastedGasBill);


      const lastBar = (yearAgoElectricBill || yearAgoGasBill) && {
        // label: yearAgobill ? "A Year Ago" : "Previous Bill",
        est: null,
        label: (!yearAgoReference) ? "Previous Bill" : "A Year Ago",
        cost: (yearAgoTotalBill?.cost ?? 0) * 100,
        end: yearAgoElectricBill?.endTimeStamp ?? yearAgoGasBill?.endTimeStamp,
        start: yearAgoElectricBill?.timeStamp ?? yearAgoGasBill?.timeStamp,
        billingPeriodId: yearAgoElectricBill?.billingPeriodId ?? yearAgoGasBill?.billingPeriodId ?? null,
        unit: {
          gas: yearAgoGasBill?.totalUnit ?? null,
          electric: yearAgoElectricBill?.totalUnit ?? null,
        },
      }

      const currentBar = {
        est: null,
        start: currentElectricBill?.timeStamp ?? currentGasBill?.timeStamp,
        end: currentElectricBill?.endTimeStamp ?? currentGasBill?.endTimeStamp,
        label: "Current Bill",
        cost: (currentTotalBill?.cost ?? 0) * 100,
        billingPeriodId: currentElectricBill?.billingPeriodId ?? currentGasBill?.billingPeriodId ?? null,
        unit: {
          gas: currentGasBill?.totalUnit ?? null,
          electric: currentElectricBill?.totalUnit ?? null,
        },
      }

      const usageSoFar = ((nextTotalBill?.cost ?? 0) * 100) ?? null;
      const projectedTotalCost = ((forecastedElectricBill?.cost ?? 0) + (forecastedGasBill?.cost ?? 0)) ?? (forecastedTotalBill?.cost ?? 0);

      // console.log('projectedTotalCost', projectedTotalCost);

      const projectedBar = isAmrUser ? null : (forecastedGasBill || forecastedElectricBill) && {
        label: "Projected Bill",
        start: estimatedCycleStart.toISOString(),
        end: estimatedCycleEnd.toISOString(),
        cost: isPendingBill ? roundToDollar(usageSoFar) : usageSoFar,
        billingPeriodId: nextElectricBill?.billingPeriodId ?? nextGasBill?.billingPeriodId ?? null,
        unit: {
          gas: nextGasBill?.totalUnit ?? 0,
          electric: nextElectricBill?.totalUnit ?? null,
        },
        est: ((projectedTotalCost ?? 0) * 100) ?? null,
        estUnit: {
          gas: (forecastedGasBill?.totalUnit && (forecastedGasBill?.totalUnit ?? 0) > 0) ? forecastedGasBill?.totalUnit : null,
          electric: forecastedElectricBill?.totalUnit ?? null,
        }
      }

      const projectionHeaderData: Array<BarData> = [
        lastBar,
        currentBar,
        projectedBar
      ]
        .filter(typedBoolean)
        .map(entry => {
          return {
            ...entry,
            est: makeGuesstimate(entry as unknown as BarData),
          } as BarData;
        });

      // console.log('projectionHeaderData', projectionHeaderData);

      const currentUsage: UsageFilterAPIRequest = {
        startDate: toZonedTime(latestDate.startDate, timeZone).toISOString(),
        endDate: toZonedTime(
          latestDate.endDate,
          timeZone
        ).toISOString(),
        serviceNetworkId,
        customerAccountNumber: accountNumber,
        interval: { granularity: 'billingperiod' },
        includeCalculated: true,
        includeToday: false,
      };


      const totalBillCost = currentBar.cost * 100; //totalMeterData.usage - DTE_STATIC_CHARGE;

      const billContributors = billingContributorsData && mapBillContributorData(
        billingContributorsData,
        totalBillCost
      );


      let peakUsages = null;

      if (peakUsageData) {

        const rateGroupThings = findRateGroupThings(peakUsageData) ?? null;

        // console.log('rateGroupThings', rateGroupThings);

        if (rateGroupThings && rateGroupThings?.usage[0]?.costContainers?.length > 1) {

          let metadataRateGroups = null

          metadataRateGroups = await fetchMetaRateGroups({
            CustomerAccountNumber: accountNumber,
            ServiceNetworkId: serviceNetworkId
          })

          peakUsages = sortPeakData(peakUsageData, metadataRateGroups);
          // console.log('peakUsages', peakUsages);
        }
      }

      //console.log(`session;dur=${sessionMS}, previousCycles;dur=${previousCyclesMS}, estimatedCycleData;dur=${nextBill?.estimatedCycleDataMS}, billImpacts;dur=${billImpactsMS}, billingContributorsData;dur=${billingContributorsDataMS}, customerFind;dur=${customerFindMS},homeProfile;dur=${homeProfileMS}`);

      if (process.env.ENV !== 'production') {
        res.setHeader(
          'Server-Timing',
          `session;dur=${sessionMS}`
        );
      }

      if (process.env.ENV == 'production') {
        res.setHeader(
          'Cache-Control',
          'public, s-maxage=300, stale-while-revalidate=300'
        )
      }

      const props: UserRouteData = {
        accountNumber,
        billContributors,
        billImpacts,
        customerId,
        customerSiteId,
        customerSites,
        fuelType,
        isLiteUser,
        isWebView,
        projectionHeaderData,
        peakUsages,
        serviceNetworkId,
        session,
        timeZone,
        utility,
        waysToSave: getWaysToSave(),
        showHomeProfileQuestions,
        currentUsage,
        isAmrUser,
        eligibilityData,
        traceparent,
        xraytraceid,
      };

      return { props };
    } catch (error: unknown) {
      console.error(error);

      if (error instanceof HTTPError) {
        if (error?.status === 404) {
          if (error?.response.url.includes('/usage/billing')) {
            const props: NoBillingData = {
              noBillingData: true,
              session,
              accountNumber,
              customerId,
              customerSiteId,
              customerSites,
              fuelType,
              isWebView,
              serviceNetworkId,
              timeZone,
              utility,
              traceparent,
              xraytraceid,
            };

            return { props };
          }

          const gatheringUsageCookie = getCookie('gathering-usage-visit-count' + accountNumber, { req, res });
          const gatheringUsageVisitCount = Number(gatheringUsageCookie) || 0;

          setCookie('gathering-usage-visit-count' + accountNumber, String(gatheringUsageVisitCount + 1), {
            req,
            res,
            maxAge: 60 * 12 * 60, // 12 hours
            path: '/',
            sameSite: 'lax',
            secure: false,
          });

          const props: FirstLoginRouteData = {
            firstLogin: true,
            utility,
            serviceNetworkId,
            customerSiteId,
            customerId,
            isWebView,
            customerSites,
            timeZone,
            accountNumber,
            fuelType,
            session,
            traceparent,
            xraytraceid,
          };

          return { props };
        }

        if (error.status === 401) {
          return {
            redirect: {
              destination: `https://${process.env.AZURE_DOMAIN_HINT}?signout=true`,
              statusCode: 302,
            },
          };
        }

        res.statusCode = error?.status;

        const props: ErrorRouteData = {
          statusCode: error?.status,
          session,
          accountNumber,
          customerId,
          customerSiteId,
          customerSites,
          fuelType,
          isWebView,
          serviceNetworkId,
          timeZone,
          utility,
          traceparent,
          xraytraceid,
        };

        return { props };
      }

      if (session?.error === 'RefreshAccessTokenError') {
        return {
          redirect: {
            destination: `https://${process.env.AZURE_DOMAIN_HINT}?signout=true`,
            statusCode: 302,
          },
        };
      }

      res.statusCode = 500;

      const props: ErrorRouteData = {
        statusCode: 500,
        session,
        accountNumber,
        customerId,
        customerSiteId,
        customerSites,
        fuelType,
        isWebView,
        serviceNetworkId,
        timeZone,
        utility,
        traceparent,
        xraytraceid,
      };

      return { props };
    }
  }
  catch (error) {
    console.error(error);
    span.recordException(error);
    span.setStatus({ code: SpanStatusCode.ERROR });
  }
  finally {
    span.end()
  }
};

const IndexPage: NextPage<RouteData> = ({
  billContributors = [],
  billImpacts = [],
  firstLogin = false,
  fuelType,
  noBillingData,
  projectionHeaderData = [],
  returnToDTE,
  shouldRedirectToLogin,
  showHomeProfileQuestions,
  statusCode,
  unknownPremise,
  isAmrUser,
  peakUsages,
  traceparent,
  xraytraceid
}) => {
  const router = useRouter();
  const webAnalytics = useWebAnalytics();

  let currentBill: BarData;
  let nextBill: BarData;
  if (projectionHeaderData.length === 3) {
    currentBill = projectionHeaderData[1];
    nextBill = projectionHeaderData[2];
  } else {
    currentBill = projectionHeaderData[0];
    nextBill = projectionHeaderData[1];
  }

  React.useEffect(() => {
    if (shouldRedirectToLogin) {
      void router.push('/login');
    }
  }, [router, shouldRedirectToLogin]);

  React.useEffect(() => {
    void webAnalytics({
      EventType: 'page_view',
      EventTag: '',
    });
    console.info('App version', version);
  }, []);

  const [alert, setAlert] = React.useState(() => {
    if (typeof window === "undefined") return;
    const urlParam = new URLSearchParams(window?.location?.search);
    const alertParam = urlParam.get('alert')
    if (!alertParam || !urlParam) return;
    urlParam.delete('alert');
    router.replace('/', undefined, { shallow: true });
    return alertParam;
  })

  if (shouldRedirectToLogin) {
    return null;
  }

  const { indexPagesTitle, traceparentText, xAmznTraceIdText } = pagesJson.IndexPages

  if (returnToDTE) {
    return (
      <>
        <Head>
          <meta name={xAmznTraceIdText} content={xraytraceid} />
          <meta name={traceparentText} content={traceparent} />
          <title>{indexPagesTitle}</title>
        </Head>
        <ReturnToDTE />
      </>
    );
  }

  if (typeof firstLogin !== 'undefined' && firstLogin) {
    return (
      <>
        <Head>
          <meta name={xAmznTraceIdText} content={xraytraceid} />
          <meta name={traceparentText} content={traceparent} />
          <title>{indexPagesTitle}</title>
        </Head>
        <GatheringUsage />
      </>
    );
  }

  if (unknownPremise) {
    return (
      <>
        <Head>
          <meta name={xAmznTraceIdText} content={xraytraceid} />
          <meta name={traceparentText} content={traceparent} />
          <title>{indexPagesTitle}</title>
        </Head>
        <UnknownPremise />
      </>
    );
  }

  if (noBillingData) {
    // && !firstLogin
    return (
      <>
        <Head>
          <meta name={xAmznTraceIdText} content={xraytraceid} />
          <meta name={traceparent} content={traceparent} />
          <title>{indexPagesTitle}</title>
        </Head>
        <TemporarilyUnavailable />
      </>
    );
  }

  if (typeof statusCode === 'number' && statusCode !== 200) {
    return (
      <>
        <Head>
          <meta name={xAmznTraceIdText} content={xraytraceid} />
          <meta name={traceparentText} content={traceparent} />
          <title>{indexPagesTitle}</title>
        </Head>
        <MissingSites statusCode={statusCode} />
      </>
    );
  }

  const diff =
    nextBill?.cost && currentBill.cost ? nextBill?.cost - currentBill.cost : 0;

  let isBillImpactActive: any = billManagementJson.BillManagement.BillImpacts.active

  const currentTotal = projectionHeaderData.find(
    cycle => cycle.label === 'Current Bill'
  ).cost;


  return (
    <main>
      {alert && <AlertModal reason={alert} setAlert={setAlert} />}
      {<ShepherdComponent wait={alert} />}
      <Head>
        <meta name={xAmznTraceIdText} content={xraytraceid} />
        <meta name={traceparentText} content={traceparent} />
        <title>{indexPagesTitle}</title>
      </Head>

      <BillProjectionHeader
        data={projectionHeaderData}
        showingBillDisclaimer={false}
        fuelType={fuelType}
        isAmrUser={isAmrUser}
        traceparent={traceparent}
        xraytraceid={xraytraceid}
      />

      <div className="hidden md:mx-auto md:flex md:w-full md:max-w-[958px] md:py-8">
        <div className="w-1/2 md:space-y-10">
          {billManagementJson.BillManagement.BillContributors.active &&
            fuelType !== 'gas' ? (
            showHomeProfileQuestions ||
              (billContributors && billContributors.length <= 1) ? (
              <div className="px-4">
                <HomeProfile
                  step={showHomeProfileQuestions ? 0 : 3}
                  traceparent={traceparent}
                  xraytraceid={xraytraceid}
                />
              </div>
            ) : billContributors ? (
              <BillContributorsCard
                billContributors={billContributors}
                billCost={currentBill.cost}
              />
            ) : null
          ) : null}

          {peakUsages && isBillImpactActive ? (
            <Fragment>
              {isBillImpactActive ? (
                <BillFactorsCard
                  isCurrentBill
                  diff={diff}
                  billImpacts={billImpacts}
                />
              ) : null}
            </Fragment>
          ) : null}

          {/* Ways to Save */}
          {hasWaysToSave && (
            <div className="waysToSave">
              {fuelType === 'gas' ? (
                <Carousel>
                  <DoYouSmellGas />
                  <FurnaceBoost />
                </Carousel>
              ) : (
                <Carousel>
                  <UpsellABTest />
                  <LightUpgrade />
                  <LeakyWindows />
                </Carousel>
              )}
            </div>
          )}
        </div>

        <div className="w-1/2 md:space-y-10">
          {peakUsages ? (
            <div>
              <PeakUsage sortedData={peakUsages} currentTotal={currentTotal} />
            </div>
          ) : isBillImpactActive ? (
            <Fragment>
              {isBillImpactActive ? (
                <BillFactorsCard
                  isCurrentBill
                  diff={diff}
                  billImpacts={billImpacts}
                />
              ) : null}
            </Fragment>
          ) : null}

          {hasApplicationOverview && (
            <div className="application">
              {/* Application Overview */}
              {fuelType === 'gas' ? (
                <Carousel>
                  <BillBreakdown />
                  <BillHistory />
                  <BillSimulator />
                </Carousel>
              ) : (
                <Carousel>
                  <BillBreakdown />
                  <BillHistory />
                  <BillSimulator />
                  <EnergyUsage />
                </Carousel>
              )}
            </div>
          )}

          <div className="px-4">
            <SupportCenter />
          </div>
        </div>
      </div>

      <div className="mx-auto mt-4 mb-4 w-full max-w-[958px] space-y-10 md:hidden">
        <div className="px-4">
          {billManagementJson.BillManagement.BillContributors.active &&
            billContributors &&
            fuelType !== 'gas' ? (
            showHomeProfileQuestions || billContributors.length <= 1 ? (
              <HomeProfile
                step={showHomeProfileQuestions ? 0 : 3}
                traceparent={traceparent}
                xraytraceid={xraytraceid}
              />
            ) : (
              <BillContributorsCard
                billContributors={billContributors}
                billCost={currentBill.cost}
              />
            )
          ) : null}
        </div>
        {isBillImpactActive && (
          <div className="px-4">
            {isBillImpactActive ? (
              <BillFactorsCard
                isCurrentBill
                diff={diff}
                billImpacts={billImpacts}
              />
            ) : null}
          </div>
        )}

        {/* Ways to Save */}
        {hasWaysToSave && (
          <div className="waysToSave px-4">
            {fuelType === 'gas' ? (
              <Carousel>
                <DoYouSmellGas />
                <FurnaceBoost />
              </Carousel>
            ) : (
              <Carousel>
                <UpsellABTest />
                <LightUpgrade />
                <LeakyWindows />
              </Carousel>
            )}
          </div>
        )}

        {peakUsages ? (
          <div>
            <div className="px-4">
              <PeakUsage sortedData={peakUsages} />
            </div>
          </div>
        ) : null}

        {/* Application Overview */}
        {hasApplicationOverview && (
          <div className="application px-4">
            <Carousel>
              <BillBreakdown />
              <BillHistory />
              <BillSimulator />
              <EnergyUsage />
            </Carousel>
          </div>
        )}

        <div className="px-4">
          <SupportCenter />
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
export { getServerSideProps };

function BillContributorsCard({
  billContributors,
  billCost,
}: {
  billContributors: Array<Contributor>;
  billCost: number;
}) {
  return (
    <div className="bills px-4">
      <CardTitle title="Bill Contributors" />
      {
        billManagementJson.BillManagement.BillContributors.active === true ?
          <BillContributors
            data={billContributors}
            totalCost={billCost}
            isLiteUser
          />
          :
          null
      }
    </div>
  );
}

function AlertModal(opts: { reason: string, setAlert: React.Dispatch<React.SetStateAction<string>> }) {

  const [showAlertModal, setShowAlertModal] = React.useState(true);
  const { indexPagesText } = pagesJson.IndexPages

  return (
    <Modal
      onClose={() => {
        opts.setAlert('');
        setShowAlertModal(false)
      }}
      show={showAlertModal}
      title=""
      actions={
        <button
          onClick={() => {
            opts.setAlert('');
            setShowAlertModal(false);
          }}
          className="block font-medium uppercase text-utility-accent"
        >
          Close
        </button>
      }
    >{indexPagesText}
    </Modal>
  )
}
// 