import { Description } from '@headlessui/react/dist/components/description/description';
import { title } from 'process';

export const billManagementJson = {
  BillManagement: {
    PeakUsage: {
      active: true,
      RateDescription:
        'You can save money and energy by shifting some of your electricity usage to off-peak hours early mornings, evenings, and weekends.',
      RateGroups: [
        {
          rateGroup: 'EFR_D1_8',
          description:
            'The price you pay for electricity is significantly lower from 11 p.m. to 7 a.m. weekdays and all weekend long, so you can save by shifting some of your usage to that time window.',
        },
        {
          rateGroup: 'EFR_D1_2',
          description:
            'You will pay a discounted rate on the energy you use between 7 p.m. and 11 a.m. and all weekend long, so you can save if you shift some of your usage to that time window.',
        },
        {
          rateGroup: 'EFR_D1_11',
          description:
            'When you use electricity between the hours of 7 p.m. and 3 p.m. and all day on the weekend, you will pay a lower rate for your energy use.',
        },
        {
          rateGroup: 'EFR_D1_13',
          description:
            'With super discounted pricing on electricity usage between 1 a.m. - 7 a.m., this rate is ideal if you have home equipment that runs overnight.',
        },
      ],
      RateMapping: [
        {
          value: '1',
          colorCode: '#b9c0c4',
          icon: '$',
        },
        {
          value: '2',
          colorCode: '',
          icon: '',
        },
        {
          value: '3',
          title: 'On-Peak',
          colorCode: '#0C2340',
          icon: '$$$',
          order: 1,
        },
        {
          value: '4',
          title: 'Off-Peak',
          colorCode: '#2DA2AF',
          icon: '$',
          order: 3,
        },
        {
          value: '5',
          colorCode: '',
          icon: '',
        },
        {
          value: '6',
          colorCode: '#648bdd',
          icon: '$',
        },
        {
          value: '7',
          colorCode: '#ec872f',
          icon: '$$',
        },
        {
          value: '8',
          colorCode: '#8a3fff',
          icon: '',
        },
        {
          title: 'Super Off-Peak',
          value: '9',
          colorCode: '#648bdd',
          icon: '$',
        },
        {
          value: '10',
          title: 'Mid-Peak',
          colorCode: '#4A7729',
          icon: '$$',
          order: 2,
        },
        {
          title: 'Critical Peak',
          colorCode: '#523178',
          icon: '$$$$',
          order: 4,
        },
      ],
      OtherCostsTitle: 'Other Costs',
      OtherCostsMessage:
        'These are other costs associated with service charges, taxes, supplemental meters and voluntary programs and services (i.e., MIGreenPower, Home Protection Plan, etc.).',
      RatesShownFirstLine:
        '*Rates shown are representative of both capacity, non-capacity, and the distribution charge on your bill.',
      RatesShownSecondLine:
        'The kWh usage displayed below may vary from your bill. Review your bill statement for billed usage.',
    },
    BillContributors: {
      active: true,
      HeathingAndCooling: 'Heating and Cooling',
      EverythingElseTitle: 'Everything Else',
      AlwaysOnTitle: 'Always On',
      GasTitle: 'Gas',
      ContributorsTitle: `Estimated Energy Costs represent regional averages of energy usage, split into home categories like kitchen or heating. These figures provide a broad guide, not precise calculations of your personal energy use.`,
    },
    BillImpacts: {
      active: true,
      OtherFactorsTitle: 'Other Factors',
      ImpactBody:
        'Other factors such as the number of residents, time spent in the home, or changes in appliances can all impact your total energy usage and bill.',
      InfoPopoverTitle: 'Average Temperature',
      InfoPopoverMessage:
        'The average temperature for your bill period is calculated by taking the average temperature of each day and dividing that number by the sum of days within the bill period.',
      BillFactorTitle: `The Bill Factors are <strong class="text-black">external</strong> factors that provide an estimated cost based on weather and length of bill periods.`,
      ImpactBodyFactors:
        'Many factors could affect different costs from one bill to another. Consider the following common examples:',
      ImpactBodyFirst: 'Changes in weather temperatures that affect HVAC usage',
      ImpactBodySecond: 'The number of days in your billing period',
      ImpactBodyThird: 'Fluctuation in your Always On usage',
      ImpactBodyForth: 'Changes in your whole home energy usage',
      CardTitleBill: 'Learn more about your current bill',
      CardTitleBillFactor: 'Factors that affected this bill',
    },
  },
};
